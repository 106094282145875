@import "../../../assets/css/index.scss";

.login_phone {
  height: 100%;

  &__heading{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 3rem;
    @media (max-width: 450px) {
      font-size: 30px;
      line-height: 2rem;
    }
  }

  &__btn{
    display: block;
    text-transform: uppercase!important;
    margin-top: 20px!important;
  }

  &__desc{
    margin-top: 40px;
    font-size: 20px;
    @media (max-width: 450px) {
      font-size: 16px;
    }

    span{
      font-size: 20px;
      cursor: pointer;
      @media (max-width: 450px) {
        font-size: 16px;
      }
    }
  }

}
