
//Material switch-----------------------------------
.MuiSwitch-thumb{
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 1px 3px rgba(0, 0, 0, 0.12) !important;
}
.MuiAccordionDetails-root {
  padding: 0 16px 16px !important;
}
.MuiAccordionSummary-content {
  margin: 0 0 12px 0 !important;
}
//Material switch-----------------------------------


//Custom css----------------------------------------
.mt-8{
  margin-top: 8px;
}
.mt-20{
  margin-top: 20px;
}
.btn-main {
  min-width: 150px!important;
  padding: 10px 16px 5px 16px!important;
}
//Custom css----------------------------------------
