@import "../../assets/css/index";

.payments {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 54px 22px 36px 22px;

  &_header{
    display: flex;
    justify-content: space-between;
    @media (max-width: 750px) {
      flex-direction: column;
      margin-bottom: 20px;
      align-items: center;
    }

    &__heading{
      font-size: 90px;
      text-transform: uppercase;
      cursor: pointer;
      @media (max-width: 1420px) {
        font-size: 70px;
      }
      @media (max-width: 450px) {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
  }

  &__content {
    margin: auto;
    display: inline-block;
    width: auto;
    border: 1px solid rgba(170, 185, 196, 0.32);
    border-radius: 8px;
    padding: 40px;
    @media (max-width: 750px) {
      padding: 20px;
    }

    form {
      @media (max-width: 750px) {
        width: 400px;
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 450px) {
        width: 300px;
        display: flex;
        flex-direction: column;
      }
    }


    &__heading{
      font-size: 40px;
      margin: 0;
      padding: 0;
    }

    &_checkbox{
      display: flex;
      align-items: start;
      margin-top: 20px;
      margin-bottom: 20px;

      &_desc{
        font-size: 20px;
        width: 415px;
        margin-left: 20px;
        margin-top: 8px;
        @media (max-width: 750px) {
          font-size: 16px;
        }
      }
    }
  }

  &_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 750px) {
      margin-top: 20px;
    }

    &__logo{
      &_img{}
      &_desc{
        @media (max-width: 750px) {
          display: none;
        }
      }
    }
    &__btn{
      &_item{
        font-size: 16px;
      }
    }
  }
}
