@import "../../assets/css/index";

.privacy_policy {
  height: 100%;
  padding: 54px 22px 36px 22px;

  &__header{
    font-size: 90px;
    text-transform: uppercase;
    cursor: pointer;
    @media (max-width: 1420px) {
      font-size: 70px;
    }
    @media (max-width: 750px) {
      font-size: 50px;
    }
  }

  &__heading{
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 120%;
    @media (max-width: 750px) {
      font-size: 20px;
    }
  }

  &__heading_h3{
    font-size: 20px;
    text-transform: uppercase;
    margin: 16px 0;
    line-height: 120%;
    @media (max-width: 750px) {
      font-size: 20px;
    }
  }

  &__body {
    font-size: 16px;
    margin: 16px 0;
    line-height: 120%;
    &:last-child {
      padding-bottom:  56px;
    }
  }
}
