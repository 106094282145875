@import "./src/assets/css/index.scss";

// TODO: убрать лишние классы и стили
*{
    box-sizing: border-box !important;
    margin: 0;
    padding: 0;
}

.app{
    height: 100vh;
    position: relative;

    &_content{
        display: flex;
        flex-direction: column;
        //padding: 54px 22px 36px 22px;
        height: 100%;
    }

    &_bg{
        position: absolute;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        z-index: -1;

        &__left{
            width: 50%;
        }
        &__right{
            position: relative;
            width: 50%;
            background: radial-gradient(100% 100% at 0% 0%, #013054 0%, #252828 100%);

            &_content{
                padding: 0 5vw;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                img{}
                &_desc{
                    font-size: 32px;
                    text-align: center;
                }
            }

            &_dec_left{
                position: absolute;
                bottom: 0px;
                left: -20px;
            }
            &_dec_right{
                position: absolute;
                top: -120px;
                right: 0px;
            }

            @media (max-width: 1475px) {
                width: 45%;
            }
        }
        
        @media (max-width: 1350px) {
            display: none;
        }
    }


}

//Notification-style
.MuiSnackbarContent-root {
    padding: 30px!important;
}

// Tabs style (login page)
.app_content {
    .MuiTabs-flexContainer {
        @media (max-width: 450px) {
            display: flex;
            flex-direction: column;
        }
    }
    .MuiTabs-indicator {
        @media (max-width: 450px) {
            display: none;
        }
    }
}

