@import "../../assets/css/index.scss";

.main {
  width: 100%;
  height: 360px;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 450px) {
    align-items: center;
  }


  &_heading {

    &_main {
      display: flex;
      justify-content: space-between;
      @media (max-width: 750px) {
        flex-direction: column;
      }

      h1 {
        font-size: 40px;
        line-height: 40px;
        @media (max-width: 450px) {
          font-size: 30px;
          line-height: 30px;
        }
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &_desc {
      display: flex;
      align-items: center;
      margin-top: 16px;
      display: none;

      img {
        margin-right: 8px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }


  &_active {

    &_main {
      font-size: 16px;
      line-height: 20px;
    }
    &_desc {
      display: flex;
      justify-content: space-between;
      align-items: end;
      font-size: 64px;
      line-height: 64px;
      margin-top: 16px;
      @media (max-width: 1500px) {
        flex-direction: column;
        align-items: start;
      }
      @media (max-width: 850px) {
        font-size: 45px;
      }

      &_btn {
        transform: translateY(-12px);
        @media (max-width: 1500px) {
          margin-top: 20px;
        }

        &_item {
          min-width: 150px!important;
          padding: 20px 20px 18px 20px!important;
        }

        img {
          margin-left: 9px;
          transform: translateY(4px);
        }
      }
    }
  }

}
