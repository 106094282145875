@import "../../../assets/css/index.scss";

.registration {

    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;

    &__content {
      display: inline-block;
      width: auto;
      border: 1px solid rgba(170, 185, 196, 0.32);
      border-radius: 8px;
      padding: 40px;
      @media (max-width: 750px) {
        padding: 20px;
      }

      form {
        @media (max-width: 750px) {
          width: 400px;
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 450px) {
          width: 300px;
          display: flex;
          flex-direction: column;
        }
      }


      &__heading{
        font-size: 40px;
        line-height: 2rem;
        margin: 0;
        padding: 0;
      }

      &_checkbox{
        display: flex;
        align-items: start;
        margin-top: 20px;
        margin-bottom: 20px;

        &_desc{
          font-size: 20px;
          width: 415px;
          margin-left: 20px;
          margin-top: 8px;
          @media (max-width: 750px) {
            font-size: 16px;
          }
        }
      }

      &_btn {
        text-transform: uppercase!important;
      }
    }

}

.item_input {
  margin-top: 24px;
}
