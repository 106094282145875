@import "../../assets/css/index.scss";


.auth{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 54px 22px 36px 22px;

  &__tabs{
    margin-top: 24px;

    &_item{
      margin-right: 10px!important;
      font-size: 13px;
      @media (max-width: 450px) {
        font-size: 10px;
      }
    }
  }

  &_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 750px) {
      flex-direction: column;
    }

    &__heading{
      font-size: 90px;
      text-transform: uppercase;
      cursor: pointer;
      @media (max-width: 1420px) {
        font-size: 70px;
      }
      @media (max-width: 450px) {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
    &__btn{}
  }

  &_routers{
    height: 100%;
  }

  &_footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    &__logo{
      &_img{}
      &_desc{
        @media (max-width: 750px) {
          display: none;
        }
      }
    }
    &__btn{
      &_item{
        font-size: 16px;
        @media (max-width: 450px) {
          font-size: 14px;
        }
      }
    }
  }
}
