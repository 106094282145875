@import "../../assets/css/index.scss";

.body {
  margin: auto;

  &_btn {
    margin-top: 20px!important;
    margin-bottom: 25px!important;
  }
}
