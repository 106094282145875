@import "../../../assets/css/index";

.main {
  display: flex;
  justify-content: center;

  &_content {
    display: inline-block;
    width: auto;
    margin-top: 40px;
    margin-bottom: 62px;
    border: 1px solid rgba(170, 185, 196, 0.32);
    border-radius: 8px;
    padding: 80px 72px;
    @media (max-width: 750px) {
      padding: 20px 35px;
    }
    @media (max-width: 450px) {
      padding: 15px 25px;
    }

    &__heading {
      font-size: 40px;
      margin: 0;
      padding: 0;
      @media (max-width: 750px) {
        font-size: 30px;
      }
      @media (max-width: 450px) {
        font-size: 20px;
      }
    }

    img {
      margin-right: 15px;
      transform: scale(1.4);
    }
  }

}
