@import "../../assets/css/index.scss";

.main{
  display: flex;
  justify-content: space-between;
  height: 200px;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
  @media (max-width: 900px) {
    justify-content: start;
    height: 310px;
  }

  .header{
    width: 50%;
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;
    @media (max-width: 1400px) {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }


    h1{
      width: 550px;
      @media (max-width: 750px) {
        width: auto;
        font-size: 50px;
        line-height: 50px;
      }
      @media (max-width: 450px) {
        width: auto;
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  .active{
    width: 50%;
    display: flex;
    justify-content: end;
    @media (max-width: 1400px) {
      width: 100%;
      justify-content: start;
      flex-direction: column-reverse;
    }

    &_personal {
      margin-right: 15px;
      @media (max-width: 1400px) {
        min-width: 270px;
        margin-right: 0px;
        margin-top: 20px;
      }
    }

    img{
      margin-right: 8px;
    }
  }
}
