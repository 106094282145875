@import "../../assets/css/index.scss";

.main {
  width: 100%;
  height: 360px;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  &_txt {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    h1 {
      @media (max-width: 750px) {
        font-size: 20px;
      }
    }
  }

  &_img {
    cursor: pointer;
    padding: 80px 0 80px 0;
    border-radius: 50%;
    transition: all 3s;

    &_horizontal, &_vertical {
      width: 150px;
      height: 4px;
      background-color: rgba(89, 119, 142, 0.6);
    }
    &_vertical {
      transform: rotate(90deg);
    }
  }
  &_img:hover {
    transition: all 3s;
    //background-color: #59778E;
    background: radial-gradient(circle, rgba(1, 48, 84, 0.18) 5%, rgba(255,255,255,1) 70%);
  }
}
