@import "../../../assets/css/index.scss";

.my_services {
  width: 100%;
  padding: 64px 24px 32px 24px;
  @media (max-width: 750px) {
    padding: 70px 10px 15px 10px;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 450px) {
      justify-content: center;
    }

    &_item {
      width: 49%;
      min-width: 270px;
      margin-top: 32px;
    }
  }
}
