@import "../../../assets/css/index.scss";

.login_mail {
  height: 100%;

  &__heading{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 3rem;
    @media (max-width: 450px) {
      font-size: 30px;
      line-height: 2rem;
    }
  }
  &__activity{
    &_pass{
      display: inline-block;
      margin-top: 32px;
      margin-bottom: 40px;
      cursor: pointer;
      font-size: 20px;
    }
    &_btn{
      min-width: 150px!important;
      padding: 10px 16px 5px 16px!important;
      display: block;
      text-transform: uppercase!important;
    }
  }
  &__desc{
    margin-top: 20px;
    font-size: 20px;
    @media (max-width: 450px) {
      font-size: 16px;
    }

    span{
      font-size: 20px;
      cursor: pointer;
      @media (max-width: 450px) {
        font-size: 16px;
      }
    }
  }
}
