@import "../../assets/css/index.scss";

.personal{
  display: flex;


  &__menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 17%;
    min-height: 100vh;
    padding: 64px 24px 32px 24px;
    background: radial-gradient(100% 100% at 0% 0%, #013054 0%, #252828 100%);
    @media (max-width: 1100px) {
      padding: 64px 14px 32px 14px;
      width: 65px!important;
    }

    &_link{
      display: flex;
      flex-direction: column;

      &_block:not(:last-child){
        margin-bottom: 68px;
      }
      &_block{
        display: flex;
        flex-direction: column;
        align-items: end;

        &_item_link {
          cursor: pointer;
        }

        &_item_noact{
          margin-bottom: 12px;
          padding: 6px 16px;
          border-radius: 4px;
          text-transform: uppercase;
          text-decoration: none;
          text-align: right;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
          @media (max-width: 1100px) {
            padding: 3px 8px;
          }
        }
        &_item_act{
          margin-bottom: 12px;
          padding: 6px 16px;
          border-radius: 4px;
          text-transform: uppercase;
          text-decoration: none;
          text-align: right;
          font-size: 14px;
          line-height: 24px;
          color: #013054;
          background-color: #FFFFFF;
          @media (max-width: 1100px) {
            padding: 3px 8px;
          }
        }
        &_item_link{
          margin-bottom: 12px;
          font-size: 16px;
          text-align: right;
          text-decoration-line: underline;
        }
        &_disabled {
          color: rgba(255, 255, 255, 0.51);
          margin-bottom: 12px;
          padding: 6px 16px;
          border-radius: 4px;
          text-transform: uppercase;
          text-decoration: none;
          text-align: right;
          font-size: 14px;
          line-height: 24px;
          @media (max-width: 1100px) {
            padding: 3px 8px;
          }
        }
      }
    }

    &_icon{
      display: flex;
      justify-content: right;

      .logo{
        img {
          @media (max-width: 1100px) {
            width: 40px;
            height: 50px;
          }
        }
      }
      .link{
        display: flex;
        align-items: end;
        img:not(:last-child){
          margin-right: 21px;
        }
        img{
          width: 24px;
          height: 24px;
        }
      }
    }
  }


  &__content{
    width: 100%;
  }

}




.menu_txt {
  @media (max-width: 1100px) {
    display: none;
  }
}
.menu_icon {
  @media (min-width: 1100px) {
    display: none;
  }
}
.requisites {
  color: white;
}
