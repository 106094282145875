@import "../../../assets/css/index.scss";


.balance{
  width: 100%;
  padding: 64px 24px 32px 24px;
  @media (max-width: 750px) {
    padding: 70px 10px 15px 10px;
  }
}


.form{

  &_heading{
    font-size: 40px;
    line-height: 3rem;
    margin-top: 120px;
    margin-bottom: 45px;
    @media (max-width: 1400px) {
      margin-top: 210px;
    }
    @media (max-width: 900px) {
      margin-top: 180px;
    }
    @media (max-width: 750px) {
      margin-top: 60px;
      font-size: 30px;
    }
  }
  &_desc{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;
  }
  Button{
    margin-top: 48px;
    @media (max-width: 750px) {
      margin-top: 20px;
    }
  }
}
