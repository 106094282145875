@import "../../../assets/css/index.scss";

.contract {
  width: 100%;
  padding: 64px 24px 32px 24px;
  @media (max-width: 750px) {
    padding: 70px 10px 15px 10px;
  }


  .detailing {
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 30px;
    @media (max-width: 850px) {
      flex-direction: column-reverse;
      align-items: start;
    }

    &_balance {
      width: 50%;
      @media (max-width: 850px) {
        width: 100%;
        margin-top: 25px;
      }

      &_name {
        margin-bottom: 15px;
      }
      &_quantity {
        font-size: 96px;
        line-height: 96px;
        margin-bottom: 15px;
        @media (max-width: 850px) {
          font-size: 70px;
          line-height: 70px;
        }
        @media (max-width: 450px) {
          font-size: 50px;
          line-height: 50px;
        }
      }
      &_btn {
        img {
          margin-left: 9px;
          transform: translateY(4px);
        }
      }
    }

    &_status {
      width: 50%;
      @media (max-width: 850px) {
        width: 100%;
      }

      &_heading {
        margin-bottom: 10px;
      }
      &_description {
        font-size: 20px;
      }

    }
    &_status div:not(:last-child) {
      margin-bottom: 30px;
      display: none;
    }

  }


  .specification {
    margin-top: 30px;

    &_heading {
      margin-bottom: 10px;
    }
    &_description {
      font-size: 25px;
      @media (max-width: 850px) {
        font-size: 20px;
      }
      @media (max-width: 450px) {
        font-size: 17px;
      }
    }
  }
  .specification div:not(:last-child) {
    margin-bottom: 40px;
  }
}

hr {
  opacity: 0.3;
}
