@font-face {
  font-family: "Muller";
  font-weight: 700;
  src: url("../fonts/MullerBold.ttf") format("ttf"),
       url("../fonts/MullerBold.woff") format("woff");
}
@font-face {
  font-family: "Muller";
  font-weight: 500;
  src: url("../fonts/MullerMedium.ttf") format("ttf"),
       url("../fonts/MullerMedium.woff") format("woff");
}@font-face {
  font-family: "Muller";
  font-weight: 400;
  src: url("../fonts/MullerRegular.ttf") format("ttf"),
       url("../fonts/MullerRegular.woff") format("woff");
}

//TODO not using
.fnt-mull-700{
  font-family: 'Muller', sans-serif !important;
  font-weight: 700 !important;
}

.fnt-mull-500{
  font-family: 'Muller', sans-serif !important;
  font-weight: 500 !important;
}

.fnt-mull-400{
  font-family: 'Muller', sans-serif !important;
  font-weight: 400 !important;
}
